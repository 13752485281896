/**
 * @module app/components/autenticacion
 */
import { Outlet } from 'react-router-dom';

import { Grid2 } from '@mui/material';
import { grey } from '@mui/material/colors';

/**
 * Layout pantalla de autenticación
 * @returns {JSX.Element}
 */
function Autenticacion() {
  return (
    <Grid2
      container
      direction='row'
      alignItems='stretch'
      sx={{ minHeight: '100vh', display: 'grid', gap: 0 }}
    >
      <Grid2 container item sx={{ gridColumn: 1, gridRow: 'span 19' }}>
        <Outlet />
      </Grid2>
      <Grid2
        container
        item
        seize={{ xs: 12 }}
        sx={{ gridColumn: 1, gridRow: '20 / 20', backgroundColor: grey[200] }}
      ></Grid2>
    </Grid2>
  );
}

export default Autenticacion;
