import { Box, Typography } from '@mui/material';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';

function TDev() {
  return (
    <Box sx={css.box}>
      <Typography>
        <EngineeringOutlinedIcon
          style={{ color: '#539170' }}
          sx={{ verticalAlign: 'middle', mr: 3, fontSize: 50 }}
        />
        <Typography
          component='span'
          color={'#5c5c5c'}
          fontWeight={600}
          fontSize={25}
          sx={{ verticalAlign: 'middle' }}
        >
          En Desarrollo
        </Typography>
      </Typography>
    </Box>
  );
}

const css = {
  box: {
    padding: '12px 14px',
    borderRadius: '12px',
    '& span': { top: '1px', position: 'relative' },
    backgroundColor: '#FFF',
    display: 'flex',
    boxShadow: '0px 2px 11px 0px rgba(69, 81, 76, 0.14)',
    justifyContent: 'center',
    mt: 2
  }
};

export default TDev;
