/**
 * @module app/components/servicios/Header
 */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useMenu, useUsuario } from '../../hooks';

import { Menu as MenuList, MenuItem, Grid2 } from '@mui/material';
import { LogoutOutlined, Menu } from '@mui/icons-material';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import perfilFoto from '../../assets/img/perfil_temporal.png';

import { AppBar as MuiAppBar, IconButton, styled, Toolbar, Typography, InputBase, Box } from '@mui/material';
import { drawerWidth, topBarHeight } from './utils';

import * as storage from '../../storage';
import * as state from '../../state';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  backgroundColor: theme.palette.primary.main,
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  minHeight: `${topBarHeight}px !important`,
  maxHeight: `${topBarHeight}px !important`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  boxShadow: 'none !important'
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#f2f2f2',
  '&:hover': {
    backgroundColor: '#f2f2f2'
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto'
  },
  margin: '6px 0'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch'
    },
    fontSize: '13px'
  }
}));

const css = {
  center: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center'
  },
  button: {
    color: '#000',
    minWidth: 0,
    maxWidth: '50px',
    ':hover': {
      backgroundColor: '#ccccd0',
      color: '#202521',
      border: 'none',
      transition: 'all .4s ease',
      webkitTransition: 'all .4s ease'
    }
  }
};

/**
 * Barra superior
 * @param {object} params
 * @param {boolean} params.open
 * @param {Function} params.abrir
 * @returns {JSX.Element}
 */
function Header({ open, abrir }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { usuario } = useUsuario();
  const menu = useMenu();

  const [anchorEl, setAnchorEl] = useState(null);

  const isMobile = useSelector(state.config.isMobile);

  const onMenuAbrir = (e) => setAnchorEl(e.currentTarget);
  const onMenuCerrar = () => setAnchorEl(null);
  const onMenuItemClick = (servicio) => {
    const rutaRedireccion = servicio.hijos.filter((s) => !!s.componente)[0].ruta;

    dispatch(state.config.setServicioActivo(servicio.ruta));
    setAnchorEl(null);
    navigate(rutaRedireccion);
  };

  const logout = () => {
    storage.token.remover();
    window.location.reload();
  };

  return (
    <AppBar position='fixed' open={open && !isMobile}>
      <Toolbar variante='dense' sx={{ minHeight: `${topBarHeight}px !important`, backgroundColor: '#FFF' }}>
        <Grid2 justifyContent='space-between' container flexWrap='nowrap' sx={{ width: '100%' }}>
          <Grid2 item sx={{ ...css.center, flexGrow: { xs: 1, md: 'initial' } }}>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={abrir}
              edge='start'
              sx={{
                marginRight: 2,
                padding: 0,
                paddingLeft: 2,
                ...(open && { display: 'none' })
              }}
            >
              <Menu />
            </IconButton>
          </Grid2>
          <Grid2 item sx={css.center}>
            <Box sx={{ marginRight: { xs: 0, md: '6px' }, height: 36 }}>
              <img
                src={usuario?.foto ? usuario.foto : perfilFoto}
                onError={(e) => (e.target.src = perfilFoto)}
                style={{ height: '100%', borderRadius: '50%' }}
                alt='Usuario'
              />
            </Box>
            <Typography
              variant='div'
              noWrap
              sx={{
                fontSize: '0.8rem',
                height: '100%',
                color: '#000',
                display: { xs: 'none', md: 'flex' },
                alignContent: 'center',
                alignItems: 'center'
              }}
            >
              Hola<b style={{ marginLeft: 3 }}>{usuario?.nombre ? ' ' + usuario.nombre : ' Usuario'}</b>
            </Typography>
            <Box sx={{ ml: 1 }}>
              {menu?.length > 1 && (
                <>
                  <IconButton sx={css.button} onClick={onMenuAbrir}>
                    <AppsIcon />
                  </IconButton>
                  <MenuList
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={onMenuCerrar}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    MenuListProps={{
                      sx: { py: 0 }
                    }}
                  >
                    {menu.map((s) => (
                      <MenuItem key={s.nombre} onClick={() => onMenuItemClick(s)} sx={{ fontSize: 14 }}>
                        {s.nombre}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
              <IconButton sx={css.button} onClick={() => logout()}>
                <LogoutOutlined />
              </IconButton>
            </Box>
          </Grid2>
        </Grid2>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
