import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGuardarDatos, useObtenerDatos } from '../../../hooks';

import { Button, FormControl, Grid2, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { TTabla } from '../../thallus';

import * as state from '../../../state';
import * as api from '../../../api';

function Recursos() {
  const { recursos } = useObtenerDatos({
    nombre: 'recursos',
    llamadaAPI: api.autorizacion.obtenerRecursos
  });

  const isMobile = useSelector(state.config.isMobile);

  const [recurso, setRecurso] = useState({});

  const { crearRecurso } = useGuardarDatos({
    nombre: 'recurso',
    llamadaAPI: api.autorizacion.crearRecurso,
    cacheActualizar: ['recursos']
  });

  const { editarRecurso } = useGuardarDatos({
    nombre: 'recurso',
    llamadaAPI: api.autorizacion.editarRecurso,
    cacheActualizar: ['recursos'],
    tipo: 'editar'
  });

  const handleOnChange = (e) => {
    setRecurso({ ...recurso, [e.currentTarget.id]: e.currentTarget.value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (!!recurso.id) {
      editarRecurso({ recurso });
    } else {
      crearRecurso({ recurso });
    }
  };

  return (
    <Grid2
      container
      direction='row'
      alignItems='stretch'
      sx={{
        width: '100%',
        display: 'grid',
        gap: 0,
        overflowY: 'scroll',
        ...(!isMobile ? { px: 3, py: 1 } : { px: 1, py: 0 })
      }}
    >
      <Grid2 item>
        <Box component='form' noValidate autoComplete='off' sx={{ mx: 'auto' }} onSubmit={handleOnSubmit}>
          <Grid2
            item
            container
            spacing={2}
            sx={{
              '& > * > *': { flexGrow: 1 }
            }}
          >
            <Grid2 item container size={{ xs: 12, lg: 3 }} alignItems={'stretch'} flexDirection={'row'}>
              <FormControl margin='dense' fullWidth>
                <TextField
                  id='nombre'
                  size='small'
                  variant='standard'
                  onChange={handleOnChange}
                  label='Nombre'
                  value={recurso.nombre ? recurso.nombre : ''}
                />
              </FormControl>
            </Grid2>

            <Grid2 item container size={{ xs: 12, lg: 6 }} alignItems={'stretch'} flexDirection={'row'}>
              <FormControl margin='dense' fullWidth>
                <TextField
                  id='descripcion'
                  size='small'
                  variant='standard'
                  onChange={handleOnChange}
                  label='Descripción'
                  value={recurso.descripcion ? recurso.descripcion : ''}
                />
              </FormControl>
            </Grid2>
          </Grid2>

          <Button
            type='submit'
            color={recurso.id ? 'secondary' : 'primary'}
            variant='contained'
            size='small'
            disableElevation
          >
            {recurso.id ? 'Editar' : 'Guardar'}
          </Button>

          <Button
            color='primary-alt'
            variant='contained'
            size='small'
            disableElevation
            sx={{ ml: 1 }}
            onClick={() => setRecurso({})}
          >
            Limpiar
          </Button>
        </Box>
      </Grid2>

      {!!recursos && (
        <Grid2 item sx={{ pt: 2 }}>
          <TTabla
            density='compact'
            selectOnClick
            columnas={[{ field: 'nombre', headerName: 'Nombre', width: 200 }]}
            filas={recursos.map(({ id, nombre, activo }) => ({
              id,
              nombre
            }))}
            onClick={(row) => {
              setRecurso(row);
            }}
          />
        </Grid2>
      )}
    </Grid2>
  );
}

export default Recursos;
