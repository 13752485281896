/**
 * @module app/components/servicios/rv/TablaArchivos
 */
import { Fragment, useEffect, useState } from 'react';
import { useSubirArchivo, useObtenerDatos, useUsuario } from '../../../hooks';

import {
  Box,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tab,
  styled,
  Collapse,
  CircularProgress,
  Tooltip,
  IconButton,
  Grid2
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import {
  CheckCircleOutline,
  CloudDownloadOutlined,
  DownloadForOfflineOutlined,
  HighlightOff,
  NewReleasesOutlined,
  WarningAmberOutlined
} from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import * as api from '../../../api';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import InfoBlock from './comun/InfoBlock';
dayjs.extend(customParseFormat);

const IconWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '9px',
  left: '9px'
}));

function ArchivosGCS({ archivos }) {
  if (archivos.length === 0) {
    return <span style={{ fontSize: '0.85rem' }}>No hay archivos para descargar.</span>;
  }

  return (
    <table>
      <tbody>
        {archivos.map((archivo, i) => (
          <tr key={archivo.fecha}>
            <td>
              <IconoEstado estado={archivo.estado_subida} size='small' bold />
            </td>
            <td style={{ width: '10px' }}></td>
            <td>
              <a
                style={{
                  ...css.archivosGCS,
                  ...(i === 0 && { fontWeight: 'bold' })
                }}
                href={archivo.url}
              >
                {archivo.nombre}
              </a>
            </td>
            <td style={{ width: '10px' }}></td>
            <td
              style={{
                ...css.archivosGCS,
                ...(i === 0 && { fontWeight: 'bold' })
              }}
            >
              {dayjs(archivo.fecha).format('DD MMM YYYY HH:mm')}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

/**
 * Tabla de archivos
 * @returns {JSX.Element}
 */
function TablaArchivos({ proceso, estadoSubiendo, configConfirmada }) {
  const { usuario } = useUsuario();

  const [pestania, setPestania] = useState(null);
  const [mostrarArchivosGCS, setMostrarArchivosGCS] = useState({});

  const { subiendo, setSubiendo } = estadoSubiendo;

  // Endpoint Subir Archivo
  const { subirArchivo } = useSubirArchivo({
    llamadaAPI: api.rv.subirArchivoProceso,
    cacheActualizar: ['procesos', `archivos_proceso_${proceso?.codigo}`],
    onSuccess: () => setSubiendo(false),
    onError: () => setSubiendo(false)
  });

  const handleOnChange = (evt) => {
    const archivo = evt.currentTarget.files[0];
    const id = evt.currentTarget.id;

    let fd = new FormData();

    fd.append('file', archivo);
    setSubiendo(id);
    subirArchivo({ proceso: proceso?.codigo, archivo: id, data: fd });
  };

  const onTabChange = (evt, v) => setPestania(gruposArchivos.find((ga) => ga.nombre === v));

  const subidos = (ga) => ga.archivos.filter((a) => a.estado === 'PROCESADO').length;
  const conErrores = (ga) => ga.archivos.filter((a) => a.estado === 'EN PROCESO').length;

  const onMostrarArchivosGCSClick = (archivo) => {
    let updateArchivosGCS = {};
    for (const key in mostrarArchivosGCS) {
      if (archivo.codigo === key) {
        updateArchivosGCS[key] = !mostrarArchivosGCS[key];
      } else {
        updateArchivosGCS[key] = false;
      }
    }
    setMostrarArchivosGCS(updateArchivosGCS);

    setTimeout(() => {
      let updateArchivosGCS = {};
      for (const key in mostrarArchivosGCS) {
        if (archivo.codigo === key) {
          updateArchivosGCS[key] = false;
        } else {
          updateArchivosGCS[key] = mostrarArchivosGCS[key];
        }
      }
      setMostrarArchivosGCS(updateArchivosGCS);
    }, 2 * 60 * 1000);
  };

  const { [`archivos_proceso_${proceso?.codigo}`]: gruposArchivos } = useObtenerDatos({
    nombre: `archivos_proceso_${proceso?.codigo}`,
    llamadaAPI: api.rv.obtenerArchivosProceso,
    parametros: { proceso: proceso?.codigo },
    condicion: !!proceso?.codigo
  });

  const {
    [`archivos_gcs_${Object.keys(mostrarArchivosGCS).find((archivo) => mostrarArchivosGCS[archivo])}`]:
      archivosGCS
  } = useObtenerDatos({
    nombre: `archivos_gcs_${Object.keys(mostrarArchivosGCS).find((archivo) => mostrarArchivosGCS[archivo])}`,
    llamadaAPI: api.rv.obtenerGCSArchivo,
    parametros: {
      proceso: proceso?.codigo,
      archivo: Object.keys(mostrarArchivosGCS).find((archivo) => mostrarArchivosGCS[archivo])
    },
    nofresh: true,
    condicion: !!Object.keys(mostrarArchivosGCS).find((archivo) => mostrarArchivosGCS[archivo]),
    gcTime: 0
  });

  useEffect(() => {
    if (!!gruposArchivos && !pestania) {
      setPestania(gruposArchivos.sort((a1, a2) => a1.orden - a2.orden)[0]);
    }
  }, [gruposArchivos, pestania]);

  useEffect(() => {
    if (!!gruposArchivos) {
      let stateDescargarArchivos = {};
      for (const ga of gruposArchivos) {
        for (const archivo of ga.archivos) {
          stateDescargarArchivos[archivo.codigo] = false;
        }
      }
      setMostrarArchivosGCS(stateDescargarArchivos);
    }
  }, [gruposArchivos]);

  if (!gruposArchivos) return <InfoBlock tipo='cargando'>Cargando Archivos ...</InfoBlock>;

  let nombres = {};
  gruposArchivos.forEach((origen) =>
    origen.archivos.forEach((archivo) => (nombres[archivo.codigo] = archivo.nombre))
  );

  const urlInstruct =
    process.env.REACT_APP_STATIC_INTERNO + '/' + usuario?.empresa + '/docs/Nombres y Formatos.pdf';

  return (
    pestania && (
      <TabContext value={pestania?.nombre ?? null}>
        <Box sx={subiendo ? { px: 4.75, opacity: 0.7, pointerEvents: 'none' } : { px: 4.75 }}>
          <TabList
            onChange={onTabChange}
            TabIndicatorProps={{ sx: css.tabIndicador }}
            sx={{ minHeight: 0 }}
            variant='scrollable'
          >
            {gruposArchivos
              ?.sort((a1, a2) => a1.orden - a2.orden)
              .map((ga, i) => (
                <Tab
                  label={
                    <>
                      {ga.nombre}
                      <div
                        style={
                          conErrores(ga) > 0
                            ? css.erroredCount
                            : subidos(ga) >= ga.archivos.filter((a) => a.requerido).length
                            ? css.completeCount
                            : css.count
                        }
                      >
                        {subidos(ga) + '/' + ga.archivos.length}
                      </div>
                    </>
                  }
                  value={ga.nombre}
                  sx={css.tabOrigen}
                  key={i}
                />
              ))}
          </TabList>
        </Box>
        {gruposArchivos?.map((ga, i) => (
          <TabPanel value={ga.nombre} key={i}>
            {process.env.REACT_APP_ENVIRONMENT !== 'testing' && (
              <Box sx={css.greySurface}>
                <Typography sx={css.tip}>
                  <span style={{ color: '#D94B4B' }}>
                    <b>¡ Recuerda !</b>
                  </span>{' '}
                  : Los archivos deben seguir el formato establecido.{' '}
                  <IconButton>
                    <a
                      href={urlInstruct}
                      target='_blank'
                      rel='noreferrer'
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      <DownloadForOfflineOutlined style={{ color: '#539170' }} />
                    </a>
                  </IconButton>
                </Typography>
              </Box>
            )}
            <Box sx={css.greySurface}>
              <Typography sx={css.tip}>
                <span style={{ color: '#D94B4B' }}>
                  <b>¡ Sugerencia !</b>
                </span>{' '}
                : Para reducir los tiempos de subida elimina las filas vacías que se encuentren debajo de tus
                tablas. Puedes hacerlo rápidamente haciendo click en la primera fila vacía, presionar
                Ctrl+Shift+↓, hacer click con el botón derecho y seleccionar 'eliminar filas'.
              </Typography>
            </Box>
            <Grid2 container spacing={2}>
              <Grid2 item size={{ xs: 12 }}>
                <Box sx={css.greySurface}>
                  <TableContainer>
                    <Table sx={{ width: '100%', minWidth: 750 }} size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Archivo</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell sx={{ width: '300px' }}></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ga.archivos
                          .sort((a1, a2) => a1.orden - a2.orden)
                          .map((archivo, j) => {
                            return (
                              <Fragment key={j}>
                                <TableRow
                                  sx={
                                    !!archivo.errores ? { '& > *': { borderBottom: 'unset !important' } } : {}
                                  }
                                >
                                  <TableCell style={{ width: 105, paddingRight: 0 }}>
                                    <Box
                                      height={22}
                                      sx={{ width: '100%', textAlign: 'left', display: 'flex' }}
                                    >
                                      {archivo.requerido && (
                                        <Tooltip
                                          title={`Este archivo es requerido`}
                                          disableInteractive
                                          placement='top'
                                          slotProps={{ tooltip: { sx: css.tooltip } }}
                                        >
                                          <NewReleasesOutlined
                                            style={{
                                              fill: archivo.estado === 'PROCESADO' ? '#6bb38c' : '#D94B4B'
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                      {archivo.dependencia && (
                                        <Tooltip
                                          title={`Este archivo depende de ${nombres[archivo.dependencia]}`}
                                          disableInteractive
                                          placement='top'
                                          slotProps={{ tooltip: { sx: css.tooltip } }}
                                          cometns
                                        >
                                          <WarningAmberOutlined
                                            style={{
                                              fill: archivo.estado === 'PROCESADO' ? '#6bb38c' : '#202020'
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </TableCell>
                                  <TableCell>{archivo.nombre}</TableCell>
                                  <TableCell>
                                    <IconoEstado estado={archivo.estado} />
                                  </TableCell>
                                  <TableCell>
                                    <label
                                      htmlFor={!subiendo ? archivo.codigo : undefined}
                                      style={{
                                        ...css.inputArchivo,
                                        ...((subiendo && subiendo !== archivo.codigo) ||
                                        proceso.estado === 'CERRADO' ||
                                        !configConfirmada
                                          ? { ...css.inputBloqueado }
                                          : {})
                                      }}
                                    >
                                      <Typography component='span' sx={css.inputArchivoTexto}>
                                        {subiendo === archivo.codigo ? (
                                          <CircularProgress
                                            size={18}
                                            sx={{ mr: 1, verticalAlign: 'middle' }}
                                          />
                                        ) : (
                                          <UploadFileOutlinedIcon
                                            sx={{
                                              verticalAlign: 'middle',
                                              position: 'relative',
                                              top: -2,
                                              mr: 0.5
                                            }}
                                          />
                                        )}
                                        {!subiendo || subiendo !== archivo.codigo
                                          ? 'Seleccionar Archivo'
                                          : 'Espera un momento'}
                                      </Typography>
                                    </label>
                                    <Input
                                      id={archivo.codigo}
                                      type='file'
                                      sx={{ display: 'none' }}
                                      inputProps={{
                                        accept:
                                          '.xlsx,.xlsb,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                      }}
                                      onChange={handleOnChange}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {proceso.estado !== 'SIN PROCESAR' && (
                                      <IconButton
                                        sx={{ padding: 0 }}
                                        onClick={() => onMostrarArchivosGCSClick(archivo)}
                                      >
                                        <CloudDownloadOutlined />
                                      </IconButton>
                                    )}
                                  </TableCell>
                                </TableRow>
                                {archivo.errores && (
                                  <TableRow>
                                    <TableCell style={{ paddingY: 0 }} colSpan={5}>
                                      <Collapse in={true} timeout='auto' unmountOnExit>
                                        <Box sx={css.errBox}>
                                          <IconWrapper>
                                            <InfoOutlinedIcon />
                                          </IconWrapper>
                                          Se encontró uno o más errores en este archivo: <br />
                                          {errores(archivo.errores)}
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                )}
                                {mostrarArchivosGCS[archivo.codigo] && (
                                  <TableRow>
                                    <TableCell colSpan={5}>
                                      <Collapse in={true} timeout='auto' unmountOnExit>
                                        <Box sx={css.gcsBox}>
                                          {!archivosGCS ? (
                                            <span style={{ paddingLeft: '50px', paddingTop: '10px' }}>
                                              <CircularProgress sx={{ color: '#202521' }} size={16} />
                                            </span>
                                          ) : (
                                            <ArchivosGCS archivos={archivosGCS} />
                                          )}
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Fragment>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid2>
              <Grid2 item size={{ xs: 0, md: 0 }}>
                <Box></Box>
              </Grid2>
            </Grid2>
          </TabPanel>
        ))}
      </TabContext>
    )
  );
}

const css = {
  tabOrigen: {
    color: '#202521',
    fontSize: 14,
    fontWeight: 300,
    padding: '6px 12px',
    textTransform: 'none',
    minHeight: 0,
    '&.Mui-selected': {
      fontWeight: '600',
      color: '#202521'
    },
    flexDirection: 'row'
  },
  tip: {
    fontSize: { xs: 12, lg: 14 }
  },
  tabIndicador: {
    backgroundColor: '#539170'
  },
  greySurface: {
    px: 2.5,
    py: 1,
    backgroundColor: '#f8f8f8',
    borderRadius: 2
  },
  errBox: {
    backgroundColor: '#FFF',
    color: '#B84747',
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.13)',
    borderRadius: '11px',
    fontSize: '12px',
    fontWeight: 600,
    position: 'relative',
    paddingLeft: '40px',
    paddingY: '12px',
    wordBreak: 'break-all',
    paddingRight: 1
  },
  gcsBox: {
    backgroundColor: '#FFF',
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.13)',
    borderRadius: '11px',
    fontSize: '12px',
    position: 'relative',
    paddingLeft: '40px',
    paddingY: '12px',
    wordBreak: 'break-all',
    paddingRight: 1
  },
  count: {
    fontSize: 12,
    display: 'inline-block',
    fontWeight: 500,
    border: '1px solid #989898',
    padding: '1px 14px',
    marginLeft: 6,
    borderRadius: 16
  },
  completeCount: {
    fontSize: 12,
    display: 'inline-block',
    fontWeight: 500,
    border: '1px solid transparent',
    backgroundColor: '#65B78C',
    padding: '1px 14px',
    marginLeft: 6,
    borderRadius: 16,
    color: '#FFF'
  },
  erroredCount: {
    fontSize: 12,
    display: 'inline-block',
    fontWeight: 500,
    border: '1px solid transparent',
    backgroundColor: '#D94B4B',
    padding: '1px 14px',
    marginLeft: 6,
    borderRadius: 16,
    color: '#FFF'
  },
  inputArchivo: {
    cursor: 'pointer'
  },
  inputArchivoTexto: {
    color: '#539170',
    fontSize: '14px',
    padding: '8px',
    position: 'relative',
    top: '1px',
    '&:hover': {
      color: '#6bb38c',
      backgroundColor: '#FFF'
    },
    fontWeight: 600
  },
  inputBloqueado: {
    opacity: 0.6,
    pointerEvents: 'none'
  },
  tooltip: {
    maxWidth: '200px',
    textAlign: 'center'
  },
  archivosGCS: {
    fontFamily: 'Roboto',
    fontSize: '0.85rem'
  }
};

function IconoEstado({ estado, size = null, bold = null }) {
  let css = {
    icono: {
      verticalAlign: 'middle'
    },
    def: {}
  };

  if (size) {
    if (size === 'small') {
      css.icono.fontSize = '15px';
      css.def.fontSize = '15px';
    }
  }

  if (bold) {
    css.icono.fontWeight = 'bold';
    css.def.fontWeight = 'bold';
  }

  if (estado === 'PROCESADO' || estado === true) {
    return <CheckCircleOutline sx={{ ...css.icono, color: '#539170' }} />;
  } else if (estado === 'EN PROCESO' || estado === false) {
    return <HighlightOff sx={{ ...css.icono, color: '#D94B4B' }} />;
  } else {
    return <Typography style={css.def}>-</Typography>;
  }
}

function errores(errores) {
  let error = [];

  for (const [err1, err2] of Object.entries(errores)) {
    if (typeof err2 === 'string') {
      error.push(err2);
    } else {
      for (const [err3, err4] of Object.entries(err2)) {
        if (Object.entries(err4).length === 0) {
          continue;
        }

        let err = (
          <Fragment>
            Hoja {err3}: <br />
            {Object.entries(err4).map(([a, b], i) => (
              <span style={{ paddingLeft: '15px' }} key={i}>
                {a} - {JSON.stringify(b)} <br />
              </span>
            ))}
          </Fragment>
        );

        error.push(err);
      }
    }
  }

  return (
    <p style={{ marginBottom: 0 }}>
      {error.map((e, i) => (
        <Fragment key={i}>
          {e}
          <br />
        </Fragment>
      ))}
    </p>
  );
}

export default TablaArchivos;
