import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useObtenerDatos from './useObtenerDatos';
import useUsuario from './useUsuario';

import * as api from '../api';

function useProcesos({ estados = null, procesoInicial = true } = {}) {
  const { procesos, isRefetching } = useObtenerDatos({
    nombre: 'procesos',
    llamadaAPI: api.rv.obtenerProcesos
  });
  const { usuario } = useUsuario();

  const { state } = useLocation();

  const [refetching, setRefetching] = useState(false);

  const [grupoModelo, setGrupoModelo] = useState(null);
  const [procesosGrupo, setProcesosGrupo] = useState(null);
  const [proceso, setProceso] = useState(null);

  if (!grupoModelo && !!usuario) {
    if (!!state?.contexto) {
      setGrupoModelo(state.contexto.grupoModelo);
    } else {
      let grupos = usuario.atributos['rv.grupo_modelo'];

      if (Array.isArray(grupos)) {
        setGrupoModelo(usuario.atributos['rv.grupo_modelo'][0]);
      } else {
        setGrupoModelo(grupos);
      }
    }
  }

  useEffect(() => {
    if (!!procesos && !!grupoModelo) {
      let procs = procesos;

      if (estados?.length) {
        procs = procs.filter((proc) => estados.includes(proc.estado));
      }

      if (grupoModelo) {
        procs = procs.filter((p) => p.grupo_modelo === grupoModelo);
      }

      setProcesosGrupo(procs);

      if (!!procesoInicial) {
        if (!!state?.contexto) {
          if (typeof state.contexto.proceso === 'string') {
            setProceso(procs.find((p) => p.codigo === state.contexto.proceso));
          } else {
            setProceso(state.contexto.proceso);
          }
        } else {
          setProceso(procs.at(0));
        }
      }
    }
  }, [procesos, grupoModelo, estados, procesoInicial, state]);

  useEffect(() => {
    if (!!procesos && !!grupoModelo) {
      if (!procesoInicial && !!proceso) {
        if (!!state?.contexto) {
          if (typeof state.contexto.proceso === 'string') {
            setProceso(procesosGrupo.find((p) => p.codigo === state.contexto.proceso));
          } else {
            setProceso(state.contexto.proceso);
          }
        }
      }
    }
  }, [grupoModelo, proceso, procesoInicial, procesosGrupo, state, procesos]);

  useEffect(() => {
    if (isRefetching) {
      setRefetching(true);
    }
  }, [isRefetching]);

  useEffect(() => {
    if (refetching && !isRefetching) {
      setProceso(procesos.find((p) => p.codigo === proceso.codigo));
      setRefetching(false);
    }
  }, [refetching, isRefetching, procesos, proceso]);

  return {
    grupoModelo: { valor: grupoModelo, procesos: procesosGrupo, setGrupoModelo },
    gruposModelos: Array.isArray(usuario?.atributos['rv.grupo_modelo'])
      ? usuario?.atributos['rv.grupo_modelo'] ?? []
      : [usuario?.atributos['rv.grupo_modelo']],
    proceso: { valor: proceso, setProceso },
    usuario
  };
}

export default useProcesos;
