/**
 * @module app/components/thallus/TInputTexto
 */

import { FormControl, TextField, Typography } from '@mui/material';

/**
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.label
 * @param {string} params.value
 * @param {string} params.error
 * @param {boolean} params.required
 * @param {Function} params.onChange
 * @returns {JSX.Element}
 */
const css = {
  label: {
    fontFamily: 'Poppins',
    marginLeft: 2.5
  },
  input: {
    fontFamily: 'Poppins',
    fontSize: 14,
    padding: '18px 22px'
  },
  borde: {
    borderRadius: '32px',
    borderColor: 'rgba(79, 79, 79, 0.35)',
    boxShadow: '0 2px 11px 0 rgba(69, 81, 76, 0.14)'
  }
};

function TInputTexto({
  id,
  label,
  value,
  error,
  required,
  onChange,
  variant = 'standard',
  placeholder = '',
  autoComplete = undefined
}) {
  return (
    (<div>
      {variant === 'outlined' ? (
        <Typography textAlign='left' color='primary.S80' sx={css.label}>
          {label}
        </Typography>
      ) : null}
      <FormControl margin='dense' fullWidth>
        <TextField
          id={id}
          size='small'
          variant={variant}
          value={value}
          onChange={onChange}
          {...(required && { required })}
          placeholder={variant === 'outlined' ? placeholder : undefined}
          label={variant === 'standard' ? label : undefined}
          {...(error && {
            error: true,
            helperText: error
          })}
          sx={{ [`& fieldset`]: css.borde }}
          autoComplete={autoComplete}
          slotProps={{
            htmlInput: {
              style: variant === 'outlined' ? css.input : {}
            }
          }}
        />
      </FormControl>
    </div>)
  );
}

export default TInputTexto;
