/**
 * @module app/components/autenticacion/Ingresar
 */

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useObtenerDatos } from '../../hooks';

import { PaginaCargando } from '../thallus';
import { Grid2, Typography } from '@mui/material';

import TFormularioIngresar from './TFormularioIngresar';
import TAuthCard from './TAuthCard';

import * as storage from '../../storage/';
import * as api from '../../api';

/**
 * Contenedor formulario de ingreso
 * @returns {JSX.Element}
 */
function Ingresar() {
  const [params] = useSearchParams();
  const pwdlss = params.get('token');
  const navigate = useNavigate();
  const qc = useQueryClient();

  const { pwdlsstkn, cargando, error } = useObtenerDatos({
    nombre: 'pwdlsstkn',
    llamadaAPI: api.autenticacion.passwordlessLogin,
    parametros: { datos: { token: pwdlss } },
    condicion: !!pwdlss,
    nofresh: true,
    usaToken: false
  });

  useEffect(() => {
    if (pwdlss && !cargando && pwdlsstkn) {
      storage.token.guardar(pwdlsstkn.token);
      qc.clear();
      navigate('/');
    }
  }, [cargando, navigate, pwdlss, pwdlsstkn, qc]);

  if (!pwdlss || (pwdlss && error)) {
    return (
      <Grid2 item container size={{ xs: 12, md: 12 }}>
        <Grid2
          item
          size={{ xs: 12 }}
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <TAuthCard>
            <Typography textAlign='left' color='primary.S80' sx={css.h5}>
              Inicia Sesión
            </Typography>
            <Typography textAlign='left' color='primary.S80' sx={css.h3}>
              Ingresa tus datos
            </Typography>
            <TFormularioIngresar />
          </TAuthCard>
        </Grid2>
      </Grid2>
    );
  } else {
    if (cargando) {
      return <PaginaCargando />;
    }
  }
}

// TODO : Mover a theme.js u similar
const css = {
  h3: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '1.875rem'
  },
  h5: {
    fontFamily: 'Poppins',
    fontWeight: '300',
    fontSize: '1.25rem'
  }
};

export default Ingresar;
