/**
 * @module app/components/thalus/TInputPassword
 */

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useState } from 'react';

/**
 * @param {object} params
 * @param {string} params.value
 * @param {string} params.error
 * @param {Function} params.onChange
 * @returns {JSX.Element}
 */

const css = {
  font: {
    fontFamily: 'Poppins',
    fontSize: 14
  },
  input: {
    padding: '18px 22px'
  },
  borde: {
    borderRadius: '32px',
    borderColor: 'rgba(79, 79, 79, 0.35)',
    boxShadow: '0 2px 11px 0 rgba(69, 81, 76, 0.14)'
  },
  label: {
    fontFamily: 'Poppins',
    marginLeft: 2.5
  }
};

function TInputPassword({ value, error, onChange, variant = 'standard', repeat = false }) {
  const [mostrar, setMostrar] = useState(false);

  return (
    <div>
      {variant === 'outlined' ? (
        <Typography textAlign='left' color='primary.S80' sx={css.label}>
          {!repeat ? 'Contraseña' : 'Repetir contraseña'}
        </Typography>
      ) : null}
      <FormControl margin='dense' fullWidth>
        <TextField
          id='th-pass'
          size='small'
          variant={variant}
          type={mostrar ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          sx={{ [`& fieldset`]: css.borde }}
          required
          label={variant === 'standard' ? 'Contraseña' : undefined}
          placeholder={variant === 'outlined' ? 'Contraseña' : undefined}
          {...(error && {
            error: true,
            helperText: error
          })}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onClick={() => {
                      setMostrar(!mostrar);
                    }}
                    onMouseDown={(event) => {
                      event.preventDefault();
                    }}
                  >
                    {mostrar ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            },

            htmlInput: { style: variant === 'outlined' ? { ...css.font, ...css.input } : { ...css.font } }
          }}
        />
      </FormControl>
    </div>
  );
}

export default TInputPassword;
