import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGuardarDatos, useObtenerDatos, useProcesos } from '../../../hooks';

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid2,
  Link,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import SelectorProcesos from './comun/SelectorProceso';
import SelectorGrupoModelo from './comun/SelectorGrupoModelo';
import InfoBlock from './comun/InfoBlock';

import * as api from '../../../api';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

function Configuracion() {
  const navigate = useNavigate();

  const { grupoModelo, gruposModelos, proceso } = useProcesos();

  const [valoresConfig, setValoresConfig] = useState(null);
  const [valoresParams, setValoresParams] = useState(null);
  const [errores, setErrores] = useState({});

  const { [`configuracion_proceso_${proceso?.valor?.codigo}`]: configProceso } = useObtenerDatos({
    nombre: `configuracion_proceso_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerConfiguracion,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor
  });

  const { [`schema_configuracion_proceso_${proceso?.valor?.codigo}`]: configSchema } = useObtenerDatos({
    nombre: `schema_configuracion_proceso_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerSchemaConfiguracion,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor
  });

  const { [`parametros_proceso_${proceso?.valor?.codigo}`]: paramsProceso } = useObtenerDatos({
    nombre: `parametros_proceso_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerParametros,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor
  });

  const { [`schema_parametros_proceso_${proceso?.valor?.codigo}`]: parametrosSchema } = useObtenerDatos({
    nombre: `schema_parametros_proceso_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerSchemaParametros,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor
  });

  useEffect(() => {
    if (!!configProceso) {
      setValoresConfig(Object.fromEntries(configProceso.valores.map((c) => [c.id + '_' + c.cid, c.valor])));
    }
  }, [configProceso]);

  useEffect(() => {
    if (!!paramsProceso && !!parametrosSchema) {
      setValoresParams(
        Object.fromEntries(
          paramsProceso.map((p) => [
            p.id + '_' + p.pid,
            formatear(parametrosSchema.find((f) => f.pid === p.pid).tipo, p.valor)
          ])
        )
      );
    }
  }, [paramsProceso, parametrosSchema]);

  let errorProceso = { errId: 1, razon: 'Cargando Configuraciones ...', tipo: 'cargando' };

  const onCambioProceso = (codigo) => {
    proceso.setProceso(grupoModelo.procesos.find((p) => p.codigo === codigo));
    setValoresConfig(null);
    setValoresParams(null);
  };

  const onCambioGrupo = (grupo) => {
    grupoModelo.setGrupoModelo(grupo);
    setValoresConfig(null);
    setValoresParams(null);
  };

  const onConfigTextChange = (event) =>
    setValoresConfig({
      ...valoresConfig,
      [event.target.id]: event.target.value
    });

  const onParamsTextChange = (event) => {
    setValoresParams({
      ...valoresParams,
      [event.target.id]: event.target.value
    });
  };

  const onConfigTextFieldBlur = (event) => {
    const campo = configSchema.find(
      (c) => c.id === event.target.name && c.cid === parseInt(event.target.dataset.cid)
    );
    const valor = valoresConfig[event.target.id];

    if (valor === null || valor === undefined) return;
    if (!campo.regex) return;

    const re = new RegExp(campo.regex);

    let regexValido =
      re.test(valor) &&
      (!campo.csv
        ? campo.tipo === 'date'
          ? dayjs(valor.trim(), 'DD-MM-YYYY', 'es', true).isValid()
          : true
        : valor.split(',').every((v) => dayjs(v.trim(), 'DD-MM-YYYY', 'es', true).isValid()));

    if (!regexValido && valor.length > 0) {
      setErrores({ ...errores, [event.target.id]: 'Valor inválido' });
    } else if (valor.length === 0 && campo.no_vacio) {
      setErrores({ ...errores, [event.target.id]: 'Valor no puede ser vacío' });
    } else {
      setErrores({ ...errores, [event.target.id]: null });
    }
  };

  const onParamsTextFieldBlur = (event) => {
    const campo = parametrosSchema.find(
      (p) => p.id === event.target.name && p.pid === parseInt(event.target.dataset.pid)
    );
    const valor = valoresParams[event.target.id];

    if (valor === null || valor === undefined) return;
    if (!campo.regex) return;

    const re = new RegExp(campo.regex);

    let regexValido = re.test(valor);

    if (!regexValido && valor.length > 0) {
      setErrores({ ...errores, [event.target.id]: 'Valor inválido' });
    } else if (valor.length === 0 && campo.no_vacio) {
      setErrores({ ...errores, [event.target.id]: 'Valor no puede ser vacío' });
    } else {
      setErrores({ ...errores, [event.target.id]: null });
    }
  };

  const {
    guardarConfiguracion,
    cargando: guardandoConf,
    guardado: confGuardada
  } = useGuardarDatos({
    nombre: 'configuracion',
    llamadaAPI: api.rv.guardarConfiguracionProceso,
    cacheActualizar:
      proceso?.valor?.estado === 'PROCESADO'
        ? [`configuracion_proceso_${proceso?.valor?.codigo}`, 'procesos']
        : [`configuracion_proceso_${proceso?.valor?.codigo}`],
    tipo: 'guardar'
  });

  const {
    guardarParametros,
    cargando: guardandoParams,
    guardado: paramsGuardado
  } = useGuardarDatos({
    nombre: 'parametros',
    llamadaAPI: api.rv.guardarParametrosProceso,
    tipo: 'guardar'
  });

  const onSubmit = (evt) => {
    evt.preventDefault();

    let conf = [];
    for (const campo in valoresConfig) {
      const cid = parseInt(campo.replace(/^\D+/g, ''));
      const nombreCampo = campo.replace(cid, '').slice(0, -1);

      conf.push({ id: nombreCampo, cid, valor: valoresConfig[campo] });
    }

    let params = [];
    for (const campo in valoresParams) {
      const pid = parseInt(campo.replace(/^\D+/g, ''));
      const nombreCampo = campo.replace(pid, '').slice(0, -1);

      const field = parametrosSchema.find((f) => f.id === nombreCampo && f.pid === pid);

      let valor = valoresParams[campo];

      if (field.tipo === 'segundos') {
        let h = valoresParams[campo].split(':');
        valor = '' + (+h[0] * 60 * 60 + +h[1] * 60);
      }

      if (field.tipo === 'moneda') {
        let p = valoresParams[campo];

        if (!p || !p.trim()) {
          valor = 0;
        } else {
          p = p.trim();

          let numFloat = parseFloat(p.replace(',', '.'));

          if (numFloat % 1 === 0) {
            valor = parseInt(numFloat, 10);
          } else {
            valor = numFloat;
          }
        }
      }

      params.push({ id: nombreCampo, pid, valor });
    }

    // if autorizado
    guardarConfiguracion({
      proceso: proceso.valor.codigo,
      config: { configuracion_confirmada: 1, valores: conf }
    });
    guardarParametros({
      proceso: proceso.valor.codigo,
      parametros: params
    });
  };

  const onModificarConfiguracion = (evt) => {
    guardarConfiguracion({ proceso: proceso.valor.codigo, config: { configuracion_confirmada: 0 } });
  };

  const onIrAResultadosClick = (evt) => {
    navigate('/servicios/rv/proceso-mensual/archivos', {
      replace: true,
      state: { contexto: { grupoModelo: grupoModelo?.valor, proceso: proceso?.valor?.codigo } }
    });
  };

  const helperText = (field) => {
    switch (field.tipo) {
      case 'date':
        return `ej.: ${dayjs().format('DD-MM-YYYY')}${field.csv ? ', ' + dayjs().format('DD-MM-YYYY') : ''}`;

      case 'segundos':
        return 'ej.: 4:30';

      default:
        return '';
    }
  };

  if (!configProceso || !paramsProceso || !configSchema || !parametrosSchema) {
    errorProceso = { errId: 1, razon: 'Cargando Configuraciones ...', tipo: 'cargando' };
  } else {
    errorProceso = { errId: 0 };
  }

  return (
    <>
      <Box sx={{ py: '20px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <Typography sx={{ ...css.h1 }} component={'span'}>
          Configuración
        </Typography>
        {grupoModelo?.procesos?.length > 0 && (
          <div>
            <SelectorGrupoModelo
              onCambioGrupo={onCambioGrupo}
              grupo={grupoModelo.valor}
              grupos={gruposModelos}
            />
            <SelectorProcesos
              onCambioProceso={onCambioProceso}
              proceso={proceso?.valor?.codigo}
              procesos={grupoModelo.procesos}
            />
          </div>
        )}

        {proceso?.valor?.estado !== 'CERRADO' && !!valoresConfig && !!valoresParams && (
          <Box sx={{ flexGrow: 1, textAlign: 'right', mt: 1 }}>
            <Button
              variant='contained'
              sx={{ ...css.actionBtn }}
              size='large'
              onClick={onModificarConfiguracion}
              disabled={!configProceso?.confirmada}
            >
              Modificar Configuración{' '}
              {(guardandoConf || guardandoParams) && configProceso?.confirmada && (
                <CircularProgress size={18} sx={{ color: '#539170' }} />
              )}
            </Button>
            <Button
              form='conf'
              type='submit'
              variant='contained'
              sx={{ ...css.actionBtn }}
              size='large'
              disabled={
                configProceso?.confirmada ||
                (Object.values(errores).length > 0 && Object.values(errores).every((v) => v)) ||
                guardandoConf ||
                guardandoParams
              }
            >
              Confirmar Configuración{' '}
              {(guardandoConf || guardandoParams) && !configProceso?.confirmada && (
                <CircularProgress size={18} sx={{ color: '#539170' }} />
              )}
            </Button>
          </Box>
        )}
      </Box>
      {errorProceso?.errId !== 10 && errorProceso?.errId !== 0 ? (
        <InfoBlock tipo={errorProceso.tipo} sx={{ marginBottom: 1 }}>
          {errorProceso.razon}
        </InfoBlock>
      ) : (
        <>
          {proceso?.valor?.estado !== 'CERRADO' && configProceso?.confirmada && (
            <InfoBlock tipo='success' sx={{ marginBottom: 1 }}>
              Configuración confirmada.{' '}
              <Link
                style={{ color: '#539170', fontWeight: 600, cursor: 'pointer' }}
                onClick={onIrAResultadosClick}
              >
                Ir a Archivos.
              </Link>
            </InfoBlock>
          )}
          <Box id='conf' component='form' noValidate autoComplete='off' onSubmit={onSubmit}>
            {configSchema?.filter((field) => !field.id.includes('codigos_')).length > 0 && (
              <SeccionFormulario
                schema={configSchema?.filter((field) => !field.id.includes('codigos_'))}
                valores={valoresConfig}
                configProceso={configProceso}
                onTextChange={onConfigTextChange}
                onTextFieldBlur={onConfigTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}
            {configSchema?.filter((field) => field.id.includes('codigos_')).length > 0 && (
              <SeccionFormulario
                titulo={'Códigos'}
                schema={configSchema?.filter((field) => field.id.includes('codigos_'))}
                valores={valoresConfig}
                configProceso={configProceso}
                onTextChange={onConfigTextChange}
                onTextFieldBlur={onConfigTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'valor_dia_vac_bono').length > 0 && (
              <SeccionFormulario
                titulo={'Valor día Bono Vacaciones'}
                schema={parametrosSchema?.filter((field) => field.id === 'valor_dia_vac_bono')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'valor_pago_cambio_rol').length > 0 && (
              <SeccionFormulario
                titulo={'Cambio al Rol'}
                schema={parametrosSchema?.filter((field) => field.id === 'valor_pago_cambio_rol')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'instr_vuelo_variable').length > 0 && (
              <SeccionFormulario
                titulo={'Instrucción por Evento'}
                schema={parametrosSchema?.filter((field) => field.id === 'instr_vuelo_variable')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'valor_bono_adicional').length > 0 && (
              <SeccionFormulario
                titulo={'Bono Adicional Tea Senior Reemplazo'}
                schema={parametrosSchema?.filter((field) => field.id === 'valor_bono_adicional')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'bono_asistencia').length > 0 && (
              <SeccionFormulario
                titulo={'Bono Asistencia'}
                schema={parametrosSchema?.filter((field) => field.id === 'bono_asistencia')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'bono_itea').length > 0 && (
              <SeccionFormulario
                titulo={'Bono ITEA'}
                schema={parametrosSchema?.filter((field) => field.id === 'bono_itea')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id.includes('valor_vacaciones_')).length > 0 && (
              <SeccionFormulario
                titulo={'Valor día Bono Vacaciones'}
                schema={parametrosSchema?.filter((field) => field.id.includes('valor_vacaciones_'))}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id.includes('pago_horas_')).length > 0 && (
              <SeccionFormulario
                titulo={'Horas de Vuelo'}
                schema={parametrosSchema?.filter((field) => field.id.includes('pago_horas_'))}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'valor_hora_vuelo_bajo_piso').length > 0 && (
              <SeccionFormulario
                titulo={'Valor Hora Vuelo Sobre Piso'}
                schema={parametrosSchema?.filter((field) => field.id === 'valor_hora_vuelo_bajo_piso')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'valor_hora_vuelo_sobre_piso_primer_tramo')
              .length > 0 && (
              <SeccionFormulario
                titulo={'Valor Hora Vuelo Sobre Piso Primer Tramo'}
                schema={parametrosSchema?.filter(
                  (field) => field.id === 'valor_hora_vuelo_sobre_piso_primer_tramo'
                )}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'feriado_trabajado').length > 0 && (
              <SeccionFormulario
                titulo={'Festivo Trabajado'}
                schema={parametrosSchema?.filter((field) => field.id === 'feriado_trabajado')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'ps_fijo_asig_esp_reentre').length > 0 && (
              <SeccionFormulario
                titulo={'Valor PS'}
                schema={parametrosSchema?.filter((field) => field.id === 'ps_fijo_asig_esp_reentre')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'valor_ps').length > 0 && (
              <SeccionFormulario
                titulo={'PS / Especial Reentr.'}
                schema={parametrosSchema?.filter((field) => field.id === 'valor_ps')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'valor_crl').length > 0 && (
              <SeccionFormulario
                titulo={'CRL'}
                schema={parametrosSchema?.filter((field) => field.id === 'valor_crl')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'valor_ps_exce').length > 0 && (
              <SeccionFormulario
                titulo={'Valor PS (Exceptions)'}
                schema={parametrosSchema?.filter((field) => field.id === 'valor_ps_exce')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'valor_hora_ofca_asby').length > 0 && (
              <SeccionFormulario
                titulo={'ASBY TEA/ Pilots Safety'}
                schema={parametrosSchema?.filter((field) => field.id === 'valor_hora_ofca_asby')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'valor_hora_semana_corrida').length > 0 && (
              <SeccionFormulario
                titulo={'Valor Hora S. Corrida > 50 hrs.'}
                schema={parametrosSchema?.filter((field) => field.id === 'valor_hora_semana_corrida')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {/* ******************************************************** */}
            {parametrosSchema?.filter((field) => field.id === 'pago_dias_vacaciones').length > 0 && (
              <SeccionFormulario
                titulo={'Pago por Días de Vacaciones'}
                schema={parametrosSchema?.filter((field) => field.id === 'pago_dias_vacaciones')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'pago_cumplimiento_objetivo_proyecto').length >
              0 && (
              <SeccionFormulario
                titulo={'Cumplimiento de Objetivos de Proyecto'}
                schema={parametrosSchema?.filter(
                  (field) => field.id === 'pago_cumplimiento_objetivo_proyecto'
                )}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'pago_eficiencia_proyecto').length > 0 && (
              <SeccionFormulario
                titulo={'Eficiencia en Proyecto'}
                schema={parametrosSchema?.filter((field) => field.id === 'pago_eficiencia_proyecto')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'valor_horas_extras').length > 0 && (
              <SeccionFormulario
                titulo={'Pago por Horas Extras'}
                schema={parametrosSchema?.filter((field) => field.id === 'valor_horas_extras')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}

            {parametrosSchema?.filter((field) => field.id === 'pago_dias_licencia').length > 0 && (
              <SeccionFormulario
                titulo={'Compensación por días de Licencia'}
                schema={parametrosSchema?.filter((field) => field.id === 'pago_dias_licencia')}
                valores={valoresParams}
                configProceso={configProceso}
                onTextChange={onParamsTextChange}
                onTextFieldBlur={onParamsTextFieldBlur}
                errores={errores}
                helperText={helperText}
              />
            )}
            {/* ******************************************************** */}
          </Box>
        </>
      )}
    </>
  );
}

function SeccionFormulario({
  titulo = null,
  schema,
  valores,
  configProceso,
  onTextChange,
  onTextFieldBlur,
  errores,
  helperText
}) {
  return (
    <Paper sx={{ ...css.card1 }}>
      {titulo && (
        <Typography sx={{ ...css.h2 }} component={'span'}>
          {titulo}
        </Typography>
      )}
      <Grid2
        container
        spacing={2}
        sx={{
          '& > * > *': { flexGrow: 1 },
          ...(titulo && { pt: 1 })
        }}
      >
        {schema
          ?.sort((a, b) => a.cols < b.cols)
          .map((field) => {
            const f_id = field.id + (field.cid ? '_' + field.cid : field.pid ? '_' + field.pid : '');

            return (
              <Grid2
                key={f_id}
                item
                container
                size={{
                  ...(field.cols && field.cols >= 6 ? { lg: 12 } : { lg: field.cols * 2 }),
                  xl: field.cols ?? 2
                }}
                // {...(field.cols && field.cols >= 6 ? { md: 12 } : { md: field.cols ?? 2 })}
                // xl={field.cols ?? 2}
                sx={{ ...css.indicador, flexDirection: 'column', justifyContent: 'center' }}
              >
                <h6>
                  {field.label}
                  {schema.map((s) => s.label).filter((label) => label === field.label).length > 1 && (
                    <span style={{ fontWeight: 'normal' }}> ({field.modelo})</span>
                  )}
                </h6>

                <FormControl>
                  <TextField
                    slotProps={{
                      htmlInput: {
                        ...(field.cid && { 'data-cid': field.cid }),
                        ...(field.pid && { 'data-pid': field.pid })
                      }
                    }}
                    id={f_id}
                    name={field.id}
                    variant='standard'
                    required
                    size='small'
                    hiddenLabel
                    value={!!valores ? valores[f_id] : ''}
                    onChange={onTextChange}
                    onBlur={onTextFieldBlur}
                    error={!!errores && !!errores[f_id]}
                    helperText={errores && errores[f_id] ? errores[f_id] : helperText(field)}
                    disabled={configProceso['confirmada']}
                    {...(field.cols && field.cols >= 6 && { multiline: true })}
                  />
                </FormControl>
              </Grid2>
            );
          })}
      </Grid2>
    </Paper>
  );
}

const css = {
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 600,
    margin: 0,
    display: 'inline-block'
  },
  h2: {
    fontSize: { xs: 17, lg: 20 },
    fontWeight: 600,
    margin: 0,
    marginBottom: 1
  },
  card1: {
    borderRadius: '16px',
    marginBottom: '20px',
    padding: '16px'
  },
  card: {
    borderRadius: '16px',
    padding: '10px 18px 2px 18px',
    boxShadow: '0px 2px 11px 0px rgba(69, 81, 76, 0.14)'
  },
  indicador: {
    '& h6': { margin: 0, padding: 0, fontSize: 14 }
  },
  borde: {
    borderRadius: '32px',
    borderColor: 'rgba(79, 79, 79, 0.35)',
    boxShadow: '0 2px 11px 0 rgba(69, 81, 76, 0.14)'
  },
  actionBtn: {
    boxShadow: 'none',
    backgroundColor: 'tentativo.btnGreen',
    color: 'white',
    borderRadius: 20,
    fontSize: { xs: 14, lg: 17 },
    padding: { xs: '6px 20px', lg: 'inherited' }
  }
};

function formatear(tipo, valor) {
  switch (tipo) {
    case 'segundos':
      const horas = Math.floor(valor / 3600);
      const minutos = Math.floor((valor % 3600) / 60);

      return `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;

    default:
      return valor;
  }
}

export default Configuracion;
