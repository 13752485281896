import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useGuardarDatos, useObtenerDatos } from '../../../hooks';

import { Box, Button, Grid2 } from '@mui/material';
import { TSelectPermisos, TSelectMenus } from '../../thallus';

import * as state from '../../../state';
import * as api from '../../../api';

function PermisosMenus() {
  const { menusPermisos } = useObtenerDatos({
    nombre: 'menusPermisos',
    llamadaAPI: api.autorizacion.obtenerMenusPermisos
  });

  const isMobile = useSelector(state.config.isMobile);

  const [menu, setMenu] = useState(null);
  const [permisos, setPermisos] = useState(null);

  // const { editarPermisosMenu } = useGuardarDatos({
  //   nombre: 'PermisosMenu',
  //   llamadaAPI: api.autorizacion.asignarPermisosMenu,
  //   cacheActualizar: ['menusPermisos', 'permisos', 'listadoPermisos'],
  //   tipo: 'editar'
  // });

  // const handleOnSubmit = (event) => {
  //   event.preventDefault();

  //   if (menu)
  //     editarPermisosMenu({
  //       menu: menu.id,
  //       permisos: permisos.map((p) => p.nombre.split('.'))
  //     });
  // };

  return (
    <Grid2
      container
      direction='row'
      alignItems='stretch'
      sx={{
        width: '100%',
        display: 'grid',
        gap: 0,
        overflowY: 'scroll',
        ...(!isMobile ? { px: 3, py: 1 } : { px: 1, py: 0 })
      }}
    >
      <Grid2 item>
        <Box component='form' noValidate autoComplete='off' sx={{ mx: 'auto' }}>
          <TSelectMenus
            label='Menu'
            onChange={(e, menu) => {
              setMenu(menu);
              // setPermisos(
              //   menusConPermisos
              //     .find((mcp) => mcp.id === menu.id)
              //     .permisos.map((p) => ({
              //       id: p.id,
              //       nombre: p.p.join('.') + (p.c ? `[${p.c}]` : '')
              //     }))
              // );
            }}
            value={menu || null}
          />
          <TSelectPermisos
            onChange={(e, v) => {
              setPermisos(v);
            }}
            value={permisos || []}
          />

          <Button type='submit' color='primary' variant='contained' disableElevation>
            Guardar
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  );
}

export default PermisosMenus;
